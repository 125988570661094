import React, { useState,useEffect,useMemo } from "react";


// Chakra imports
import {
  Avatar,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Box,
  Flex,
  Icon,
  Link,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spacer,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import {AddIcon,DownloadIcon } from '@chakra-ui/icons'
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import PatientsTableRow from "components/Tables/PatientsTableRow";
import PatientForm from "components/Patients/PatientForm";
import { tablesProjectData, tablesTableData } from "variables/general";
import PatientsTable from "components/Tables/PatientsTable";

//API Service
import {useFetch} from 'services/useFetch';
import { useTable } from 'react-table'
import { FaFilePdf } from "react-icons/fa";
import DateFilterTable from "components/Tables/DateFilterTable";


function LabTable(props) {
  const {data,loading,error,fetchData} = useFetch({method:'get',url:`labs/bloodtest?patient=${props?.patient.id}`})
  const textColor = useColorModeValue("gray.700", "white");
  const moment= require('moment') 

useEffect( () =>{
  console.log(data);
},[data]);
 
  const columns = useMemo(()=>[
    {
      id:'date',
      Header: 'Collection Date',
      accessor: d => `${moment(d.date).format('DD MMM y')}`, // accessor is the "key" in the data
      filter: "dateFilter",
      // filter: (rows, id, filterValue) => {
      //   return rows.filter(
      //     (row) =>
      //       filterValue.length <= 0 ||
      //       !filterValue ||
      //       filterValue.includes(row.values[id])
      //   );
      // },
      Cell: row =>{
        return(
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {row.value}
          </Text>
        )
      }
    },
    // {
    //   Header: 'Haemoglobin',
    //   accessor: d => `${d.data.haemoglobin}`,// accessor is the "key" in the data
    //   Cell: row =>{
    //     return(
    //       <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
    //         {row.value}
    //       </Text>
    //     )
    //   } 
    // },
    // {
    //   Header: 'Platelets',
    //   accessor: d => `${d.data.platelets}`,// accessor is the "key" in the data
    //   Cell: row =>{
    //     return(
    //       <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
    //         {row.value}
    //       </Text>
    //     )
    //   } 
    // },
    {
      Header: '',
      accessor: 'pdf',// accessor is the "key" in the data
      Cell: row => {
        return(
          <Button p="0px" bg="transparant" isDisabled={row.value ? false : true }>
            <Link href={row.value} target="_blank" download>
            <Flex alignItems="center" p="12px">
              <Icon as={FaFilePdf} color='teal.300' w="20px" h="auto" me="5px" />
              <Text fontSize="md" color='teal.300' fontWeight="bold">
                PDF
              </Text>
            </Flex>
            </Link>
          </Button>
        )
      }
    },
  ],[])


  return (
      <Card p="16px" overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Pathology Results 
          </Text>
        </CardHeader>
        <CardBody>
          {error && (
            <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Error!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
          )}
          {loading && (
          <Box w="100%" align="center">
            <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                            />
          </Box>
          )}
          {data && data.length ? ( 
            <DateFilterTable columns={columns} data={data} />
          ):(
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>No pathology results yet</AlertDescription>
          </Alert>
          )}
        </CardBody>
      </Card>
    
  );
}

export default LabTable;
