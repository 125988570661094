import React, { useState,useEffect,useMemo, useCallback } from "react";
import {Link as ReactLink} from 'react-router-dom';

// Chakra imports
import {
  Avatar,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Badge,
  Button,
  Box,
  Flex,
  Link,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spacer,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import {AddIcon, } from '@chakra-ui/icons'
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import PatientsTableRow from "components/Tables/PatientsTableRow";
import PatientForm from "components/Patients/PatientForm";
import { tablesProjectData, tablesTableData } from "variables/general";
import PatientsTable from "components/Tables/PatientsTable";

//API Service
import {useFetch} from 'services/useFetch';
import { useTable } from 'react-table'


function Patients() {
  const {data,loading,error,fetchData} = useFetch({method:'get',url:"accounts/patients/"});
  const [patients,setPatients]=useState('');
  const[editPatient,setEditPatient]=useState('');
  const textColor = useColorModeValue("gray.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [patientDeceasedView,setDeceasedView] = useState(false)

  const addClicked = () => {
    setEditPatient(null);
    onOpen();
  }
  const editClicked = patient => {
    setEditPatient(patient);
    onOpen();
  }

  useEffect(()=>{
    setPatients(data);
  },[data])

  console.log(patients);
 
  const columns = useMemo(()=>[
    {
      Header: 'Patient',
      accessor: d => `${d.user.first_name} ${d.user.last_name}`,// accessor is the "key" in the data
      Cell: row=>{
        return(
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar size="md" name={row.cell.value} borderRadius="12px" me="10px" />
          <Flex direction="column">
            <Link
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
              as={ReactLink}
              to={{ pathname:"/admin/profile",state:{patient:row.row.original}, }}
            >
              {row.value}
            </Link>
          </Flex>
        </Flex>
        )

      },
      id:'name',
      show:false,
    },
    {
      Header: 'Medical Aid',
      accessor: d => `${d.medical_aid} ${d.medical_aid_number}`, // accessor is the "key" in the data
      Cell: row => {
        return (
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {row.row.original.medical_aid}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {row.row.original.medical_aid_number}
            </Text>
        </Flex>
        )
      }
    },
    {
      Header: 'ID',
      accessor: 'national_id', // accessor is the "key" in the data
      Cell: row =>{
        return(
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {row.value}
        </Text>
        )
      }
    },
    {
      Header: 'Cellphone',
      accessor: 'cellphone',// accessor is the "key" in the data
      Cell: row =>{
        return(
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {row.value}
          </Text>
        )
      } 
    },
    {
      Header: 'Status',
      accessor: d=> d.is_deceased ? 'Deceased' : 'Active',// accessor is the "key" in the data
      Cell: row =>{
        return(
          <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
            {row.value == 'Active' ? 
              <Badge colorScheme="green">Active</Badge> 
              : <Badge colorScheme="red">Deceased</Badge> }
          </Text>
        )
      } ,
    },
    {
      Header: '',
      accessor: 'none',// accessor is the "key" in the data
      Cell: row => {
        return(
          <Button p="0px" bg="transparent" variant="no-hover" onClick={() => editClicked(row.row.original)}>
            <Text
              fontSize="md"
              color="gray.400"
              fontWeight="bold"
              cursor="pointer"
            >
              Edit
            </Text>
          </Button>
        )
      }
    },
  ],[])

 

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Patients 
          </Text>
          <Spacer/>
          <Button size="sm" leftIcon={<AddIcon />} colorScheme="teal" onClick={addClicked}>
            Add Patient
          </Button>
        </CardHeader>
        <CardBody>
          {error && (
            <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Error!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
          )}
          {loading && (
          <Box w="100%" align="center">
            <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                            />
          </Box>
          )}
          {patients ? ( 
            <PatientsTable columns={columns} data={patients} />
          ):(
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Error!</AlertTitle>
            <AlertDescription>No Patients Found!</AlertDescription>
          </Alert>
          )}
        </CardBody>
      </Card>
      
      <PatientForm
      isOpen={isOpen}
      onClose={onClose}
      patient={editPatient}
      fetch = {() => {fetchData({method:'get',url:"accounts/patients/"})}}
      />
    </Flex>
    
  );
}

export default Patients;
