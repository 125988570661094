import React, { useState,useEffect,useMemo } from "react";

// Chakra imports
import {
  Avatar,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Box,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spacer,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import {AddIcon, } from '@chakra-ui/icons'
import moment from "moment";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import PatientsTableRow from "components/Tables/PatientsTableRow";
import PatientForm from "components/Patients/PatientForm";
import { tablesProjectData, tablesTableData } from "variables/general";
import LabForm from "components/Labs/LabForm";

//API Service
import {useFetch} from 'services/useFetch';
import { useTable } from 'react-table'


function Labs() {
  
  const [patients,setPatients]=useState('');
  const[editPatient,setEditPatient]=useState('');
  const textColor = useColorModeValue("gray.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [monday,setMonday] = useState(moment().startOf('isoWeek'));

  const nextWeek = () => {
    setMonday(monday.add(7,"days"));
    console.log(monday);
  }

  const data = React.useMemo(
    () => [
      {
        mon: 'Hello',
        tue: 'World',
        wed:'Wednesday',
        thu:'Thursday',
        fri:'Friday',
        sat:'Saturday',
      },
      {
        mon: 'Hello',
        tue: 'World',
        wed:'Wednesday2',
        thu:'Thursday',
        fri:'Friday',
        sat:'Saturday',
      },
    ],
    []
  )

  const columns = React.useMemo(
    () => [
      {
        // Header: ({ state }) => monday.add(7 * state.pageIndex, "days").format("ddd Do MMM"),
        Header: monday.format("ddd Do MMM"),
        accessor: 'mon', // accessor is the "key" in the data
      },
      {
        Header: 'Tuesday',
        accessor: 'tue',
      },
      {
        Header: 'Wednesday',
        accessor: 'wed',
      },
      {
        Header: 'Thursday',
        accessor: 'thu',
      },
      {
        Header: 'Friday',
        accessor: 'fri',
      },
      {
        Header: 'Saturday',
        accessor: 'sat',
      },
    ],
    []
  )

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Add Lab Results
          </Text>
          <Spacer/>
        </CardHeader>
        <CardBody>
            <Box w="100%">
              <LabForm/>
            </Box>
        </CardBody>
      </Card>
    </Flex>
    
  );
}

export default Labs;
