import React,{useState,useEffect,useMemo} from 'react';
import { useForm, } from "react-hook-form";
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    FormLabel,
    FormErrorMessage,
    FormControl,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    Select,
    Stack,
    SimpleGrid,
    Switch,
    Text,
    Textarea,
  } from "@chakra-ui/react"
import { useToast } from "@chakra-ui/react"

//API Service
import API from 'services/api'

//UTILS
import RandomPassword from 'services/RandomPassword';
import useToggle from "services/useToggle"
import { tsPropertySignature } from '@babel/types';


function PatientForm(props) {
    const { isOpen, onOpen, onClose } = props;
    const [patient,setPatient]=useState({})
    const [isDeceased,toggleDeceased] = useToggle();
    const firstField = React.useRef()
    const toast = useToast()
    const {
      handleSubmit,
      register,
      reset,
      formState: { errors, isSubmitting },
      setError,
    } = useForm({
      defaultValues: patient,
    });

    useEffect( () => {     
      console.log("PATIENT RESET");
      if (props.patient) {
        reset(props.patient)
        console.log(isDeceased)
      } else {
        reset({})
      }  
    },[props.patient])

    const onSubmit = (data) => {
      console.log(data);
      return props.patient
        ? updatePatient(data)
        : createPatient(data)

    }

    async function createPatient(new_patient){
      console.log(RandomPassword())
      new_patient['user']['password'] = RandomPassword()
      await API({
        method: 'POST',
        url:`accounts/patients/`,
        data: new_patient,
      })
      .then(resp => {
        console.log(resp)
        toast({
            title: "Patient Created.",
            description: `Patient ${new_patient.user.first_name} Created`,
            status: "success",
            duration: 9000,
            isClosable: true,
        })
        props.onClose()
        props.fetch()
      })
      .catch(err => {
        console.log(err.response)
        try {
          for (let [field, messages] of Object.entries(err.response.data)) {
            // console.log(`key: ${field}, value: ${err.response.data[field]}`)
            if (field === 'user'){
              for (let [user_field, user_messages] of Object.entries(err.response.data[field])) {
                console.log(`key: ${user_field}, value: ${user_messages}`)
                setError("user.email", { type: 'server', message: user_messages.join(' ') });
              }
            } else {
              setError(field, { type: 'server', message: messages.join(' ') });
            }
          }
          console.log(err.response.data)
        }
        catch (error){
          console.log(error);
        }
      })
    };

    async function updatePatient(updated_patient){
      console.log(updated_patient)
        await API({
          method: 'PATCH',
          url:`accounts/patients/${updated_patient.id}/`,
          data: updated_patient,
        })
        .then(resp => {
          console.log(resp)
          toast({
              title: "Patient Updated.",
              description: `Patient ${updated_patient.user.first_name} Updated`,
              status: "success",
              duration: 9000,
              isClosable: true,
          })
          props.onClose()
          props.fetch()
        })
        .catch(err => {
          try {
            for (let [field, messages] of Object.entries(err.response.data)) {
              if (field === 'user'){
                for (let [user_field, user_messages] of Object.entries(err.response.data[field])) {
                  console.log(`key: ${user_field}, value: ${user_messages}`)
                  setError("user.email", { type: 'server', message: user_messages.join(' ') });
                }
              } else {
                setError(field, { type: 'server', message: messages.join(' ') });
              }
            }
            console.log(err.response.data)
          }
          catch (error){
            console.log(error);
          }
        })
      };
    

  
    return (
      <>
        <Drawer
          isOpen={props.isOpen}
          placement="right"
          initialFocusRef={firstField}
          onClose={props.onClose}
          size="lg"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader borderBottomWidth="1px">
                {!props.patient ?
                  <span>Create a new account</span> :
                  <span>Edit account for {props.patient.user.first_name}</span>
                }
              </DrawerHeader>
    
              <DrawerBody>
                  <SimpleGrid columns={2} spacing="24px">
                    <Box>
                      <FormControl isInvalid={errors?.user?.first_name} isRequired>
                        <FormLabel htmlFor="first_name">First Name</FormLabel>
                        <Input
                          ref={firstField}
                          id="first_name"
                          placeholder="Please enter first name"
                          // value={patient ? patient.user.first_name : ''}
                          {...register("user.first_name", {
                            required: "Required",
                            maxLength: {value:20,message:"Max 20 characters"},
                          })}
                          // onChange={ evt=> setPatient(state=> ({ ...state,first_name:evt.target.value}))}
                        />
                        <FormErrorMessage>
                          {errors?.user?.first_name && errors?.user?.first_name.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl isInvalid={errors?.user?.last_name} isRequired>
                        <FormLabel htmlFor="last_name">Last Name</FormLabel>
                        <Input
                          id="last_name"
                          placeholder="Please enter last name"
                          {...register("user.last_name", {
                            required: "Required",
                            maxLength: {value:20,message:"Max 20 characters"}
                          })}
                        />
                        <FormErrorMessage>
                          {errors?.user?.last_name && errors?.user?.last_name.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl isInvalid={errors?.national_id} isRequired>
                        <FormLabel htmlFor="national_id">ID Number</FormLabel>
                        <Input
                          id="national_id"
                          placeholder="Please enter ID Number"
                          {...register("national_id", {
                            required: "Required",
                            maxLength: {value:15,message:"Max 15 characters"}
                          })}
                        />
                        <FormErrorMessage>
                          {errors?.national_id && errors?.national_id.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl isInvalid={errors?.user?.email} isRequired>
                        <FormLabel htmlFor="email">E-mail</FormLabel>
                        <Input
                          id="email"
                          type="email"
                          placeholder="Please enter email"
                          {...register("user.email", {
                            required: "Required",
                          })}
                        />
                        <FormErrorMessage>
                          {errors?.user?.email && errors?.user?.email.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl isInvalid={errors?.cellphone}>
                        <FormLabel htmlFor="cellphone">Cellphone</FormLabel>
                        <Input
                          id="cellphone"
                          placeholder="Please enter Cellphone Number"
                          {...register("cellphone")}
                        />
                        <FormErrorMessage>
                          {errors?.cellphone && errors?.cellphone.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl isInvalid={errors?.blood_type}>
                        <FormLabel htmlFor="blood_type">Blood Type</FormLabel>
                        <Select
                          id="blood_type"
                          placeholder="Select Blood Type"
                          {...register("blood_type")}
                        >
                          <option value="O-">O-</option>
                          <option value="O+">O+</option>
                          <option value="A-">A-</option>
                          <option value="A+">A+</option>
                          <option value="B-">B-</option>
                          <option value="B+">B+</option>
                          <option value="AB-">AB-</option>
                          <option value="AB+">AB+</option>
                        </Select>
                        <FormErrorMessage>
                          {errors?.blood_type && errors?.blood_type.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl isInvalid={errors?.medical_aid}>
                        <FormLabel htmlFor="medical_aid">Medical Aid</FormLabel>
                        <Input
                          id="medical_aid"
                          placeholder="Please enter Medical Aid"
                          {...register("medical_aid")}
                        />
                        <FormErrorMessage>
                          {errors?.medical_aid && errors?.medical_aid.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl isInvalid={errors?.medical_aid_number}>
                        <FormLabel htmlFor="medical_aid_number">Medical Aid Number</FormLabel>
                        <Input
                          id="medical_aid_number"
                          placeholder="Please enter Medical Aid Number"
                          {...register("medical_aid_number")}
                        />
                        <FormErrorMessage>
                          {errors?.medical_aid_number && errors?.medical_aid_number.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl isInvalid={errors?.is_deceased}>
                        <FormLabel htmlFor="is_deceased">Mark Patient as Deceased</FormLabel>
                        <InputGroup>
                          <Switch size="lg" {...register(`is_deceased`)} onChange={toggleDeceased}/>
                        </InputGroup>
                        <FormErrorMessage>
                          {errors?.is_deceased && errors?.is_deceased.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  
                  </SimpleGrid>
              </DrawerBody>
              <DrawerFooter borderTopWidth="1px">
                <Button variant="outline" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="blue" isLoading={isSubmitting} type="submit">Submit</Button>
              </DrawerFooter>
            </DrawerContent>
          </form>
        </Drawer>
      </>
    )
  }

export default PatientForm;

