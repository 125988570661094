import { createContext, useContext, useState } from 'react';
import TokenService from "services/token.service";
import AuthService from "services/auth.service";

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}


  