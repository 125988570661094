import React,{useState,useEffect} from "react";

import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
    Button,
    Center,
    Flex,
    Grid,
    HStack,
    Link,
    Spacer,
    Spinner,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatGroup,
    Switch,
    Text,
    Table,
    Th,
    Tbody,
    Thead,
    Tr,
    Td,
    VStack,
    useColorModeValue,
  } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

  //API SERVICE
  import {useFetch} from 'services/useFetch';

  export default function MedicationsTable(props) {
    const textColor = useColorModeValue("gray.700", "white");
    const {data,loading,error,fetchData} = useFetch({method:'get',url:`prescriptions/latestmed?patient=${props?.patient?.id}`});
    const {data:latestLab,loading:labLoading,error:labError,fetchData:labFetchData} = useFetch({method:'get',url:`labs/latestbloodtest?patient=${props?.patient?.id}`});
    const [patient,setPatient] = useState();
    const moment = require('moment');
    
    useEffect( () =>{
      console.log(latestLab);
      console.log(data);
    },[data,latestLab]);

    return (
    <>
      <Card p="16px">
        <CardHeader p="12px 5px" mb="0px">
          <Text fontSize="lg" color={textColor} fontWeight="bold">
            Medicine Prescription
          </Text>
          <Spacer/>
          {data &&
          <>
           <Center>
            <Text pr={5} fontSize="md" color={textColor} fontWeight="bold">
              {moment(data.prescription_date).format('DD MMM y')}
            </Text>
            <Button size="xs" colorScheme="teal">
              <Link href={data.pdf} target="_blank" download>
                <Text fontSize="sm">
                  PDF
                </Text>
              </Link>
            </Button>
          </Center>
          </>
          }
        </CardHeader>
          <CardBody px="5px">
          {loading && <Spinner thickness="4px" color="teal.400" size="lg"/>}
          {data ? ( 
            <VStack w="100%">
            <StatGroup w="100%">
              <Stat h="100%" mr={2} align="center" p="5px" border="1px" borderRadius="lg" borderColor="gray.200">
                <StatLabel>Target Hb</StatLabel>
                <StatNumber color="teal.400"> + 13.0 (g/dl)</StatNumber>
                <StatHelpText>
                  Current Hb:  
                  {labLoading && <Text as="span" color="red">Loading...</Text>}
                  {labError && <Text as="span" color="red">{labError}</Text>}
                  {latestLab && <Text as="span" color="red"> {latestLab.data?.haemoglobin}</Text>}
                </StatHelpText>
              </Stat>
              
              <Stat p="5px" border="1px" borderRadius="lg" borderColor="gray.200">
                <StatLabel align="center">Target Transferrin saturation:</StatLabel>
                <StatNumber align="center" color="teal.400">20-30%</StatNumber>
                <StatHelpText align="center">
                  Current Transferrin saturation:  
                  {labLoading && <Text as="span" color="red">Loading...</Text>}
                  {labError && <Text as="span" color="red">{labError}.</Text>}
                  {latestLab && <Text as="span" color="red"> {latestLab.data?.transferrin} %</Text>}
                </StatHelpText>
              </Stat>
            </StatGroup>
            <Text as="u" fontSize="sm" fontWeight="bold" color={textColor}>
              Erythropoietin Treatment Regimen
            </Text>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th w="50%"></Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td fontWeight="bold">Erythropoietin</Td>
                  <Td>{data.erythropoietin}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Dosage</Td>
                  <Td>{data.erythropoietin_dosage} {data.epo_dosage_unit?.name}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Frequency</Td>
                  <Td>{data.erythropoietin_frequency} {data.epo_frequency_unit?.name}</Td>
                </Tr>
              </Tbody>
            </Table>
            <Text as="u" fontSize="sm" fontWeight="bold" color={textColor}>
                Iron Treatment Regimen
              </Text>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th w="50%"></Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td fontWeight="bold">Iron Supplement</Td>
                  <Td>{data.iron_supplement}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Dosage</Td>
                  <Td>{data.iron_dosage} {data.iron_dosage_unit?.name}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Frequency</Td>
                  <Td>{data.iron_frequency} {data.iron_frequency_unit?.name}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight="bold">Additional Supplements/Notes</Td>
                  <Td whiteSpace="pre-wrap">{data.notes}</Td>
                </Tr>
              </Tbody>
            </Table>
            </VStack>
            ): (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>No medication prescribed yet</AlertDescription>
            </Alert>
            )
            }
          </CardBody>
      </Card>
    </>
)}