import API from "./api";
import axios from "axios";
import TokenService from "./token.service";
import { AiOutlinePicCenter, AiOutlinePieChart } from "react-icons/ai";
import { Redirect } from "react-router-dom";

const login = async ({email, password}) => {
    const response = await API
        .post("/accounts/jwt/create/", {
            email,
            password,
        });
    if (response.data) {
        console.log(response.data);
        return response.data;
    } else {
        throw 'Invalid Credentials';
    }
};

const verifyToken = (token) => {
    return API
        .post("/accounts/jwt/verify/",{token})
        .then((resp) => {return resp.status})
        .catch(error => {
            console.log(error)
         })
};

const logout = () => {
    TokenService.removeToken();
};

const getCurrentUser = () => {
    return API.get("/accounts/users/me/")
}

const refreshToken = async () => {
    try {
        const rs = await API.post("accounts/jwt/refresh/", {
            refresh: TokenService.getLocalRefreshToken(),
        });
        const accessToken = rs.data.access;
        TokenService.updateLocalAccessToken(accessToken);
        console.log(accessToken);
        return console.log("refresh token success")

    } catch (_error) {
        console.log(_error)
        TokenService.removeToken();
        <Redirect to="/auth/login" />
        return _error;
    }
}


const getUserRole = async () => {
    await API.get("/accounts/users/me/")
        .then((resp) => {return resp.data.is_admin})
        .catch(error => {
            console.log(error)
        })
}



export default {
  login,
  logout,
  getCurrentUser,
  verifyToken,
  getUserRole,
  refreshToken,
};