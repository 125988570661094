import React, { useState } from "react";
import generator from "generate-password";

export default function RandomPassword(){
    var generator = require('generate-password');

    var password = generator.generate({
        length: 10,
        numbers: true
    });

    return password
}