import React, { useState,useEffect,useMemo } from "react";
import { useTable,usePagination,useSortBy,useGlobalFilter,useAsyncDebounce } from 'react-table'
import {
    Input,
  } from "@chakra-ui/react";

export default function TableSearch({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200);
  
    return (
      <Input
        variant="filled"
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search`}
      />
    )
  }