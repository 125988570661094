import {useState,useEffect} from 'react';
import API from './api';

export const useFetch = (axiosParams) => {
    const [data, setData] = useState(undefined);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const fetchData = async (params) => {
      try {
       const result = await API(params);
       setData(result.data);
       } catch( error ) {
         setError(error);
       } finally {
         setLoading(false);
       }
    };

    useEffect(() => {
        fetchData(axiosParams);
    }, []); // execute once only

    return { data, loading, error, fetchData };
    //return { data, error, loading };
};


//OLD VERSION
// function useFetch({method,url,body=null}){
//     const [data,setData] = useState([]);
//     const [loading,setLoading] = useState(true);
//     const [error,setError] = useState();

//     useEffect( ()=>{
//         async function fetchData() {
//             setLoading(true);
//             setError();
//             const data = await API[method](url)
//                                 .catch(err => setError(err))
//             setData(data.data)
//             setLoading(false)
//         }
//         fetchData();
//     },[method,url,body]);

//     return [data,loading,error]

// }

// export {useFetch}
