/*!

=========================================================
* Purity UI Dashboard - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/purity-ui-dashboard/blob/master/LICENSE.md)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React , {useState,useEffect} from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import ProtectedRoute from "routes/ProtectedRoute.js";
import { AuthContext } from "./context/auth";
import TokenService from "services/token.service";
import AuthService from "services/auth.service";

function App(){
  const [authTokens, setAuthTokens] = useState();

  const setTokens = (data) => {
    TokenService.setToken(data);
    setAuthTokens(data);
  }
 //can later pass user object to Provider

  useEffect( () => {
    if (TokenService.getToken()){
      setAuthTokens(TokenService.getToken());
    }
  },[]);

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <HashRouter>
        <Switch>
          <Route path={`/auth`} component={AuthLayout} />
          <ProtectedRoute path={`/admin`} component={AdminLayout} />
          <Route path={`/rtl`} component={RTLLayout} />
          <Redirect from={`/`} to="/admin/dashboard" />
        </Switch>
      </HashRouter>
    </AuthContext.Provider>
  );
}

ReactDOM.render(
  <App/>,
  document.getElementById("root")
);
