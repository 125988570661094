import React,{useEffect} from "react";

import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
    Button,
    Center,
    Flex,
    Grid,
    Link,
    Spacer,
    Spinner,
    Switch,
    Text,
    Table,
    Th,
    Tbody,
    Thead,
    Tr,
    Td,
    useColorModeValue,
  } from "@chakra-ui/react";
  import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

  //API SERVICE
  import {useFetch} from 'services/useFetch';

  export default function DialysisTable(props) {
    const {data,loading,error,fetchData} = useFetch({method:'get',url:`prescriptions/latesthd?patient=${props?.patient?.id}`})
    const textColor = useColorModeValue("gray.700", "white");
    const moment = require('moment');

    useEffect( () =>{
      console.log(error);
      console.log(data);
    },[data,error]);

    return (
      <>
     
      <Card p="16px">
      <CardHeader p="12px 5px" mb="0px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Dialysis Prescription
        </Text>
        <Spacer/>
        {data &&
        <>
          <Center>
          <Text pr={5} fontSize="md" color={textColor} fontWeight="bold">
            {moment(data.prescription_date).format('DD MMM y')}
          </Text>
          <Button size="xs" colorScheme="teal">
            <Link href={data.pdf} target="_blank" download>
              <Text fontSize="sm">
                PDF
              </Text>
            </Link>
          </Button>
          </Center>
        </>
        }
      </CardHeader>
      <CardBody px="5px">
        {loading && <Spinner thickness="4px" color="teal.400" size="lg"/>}
        {data ? ( 
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th></Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td fontWeight="bold">Frequency</Td>
              <Td/>
              <Td>{data.frequency_per_week} times a week</Td>
              
            </Tr>
            <Tr>
              <Td fontWeight="bold">Duration</Td>
              <Td/>
              <Td>{data.duration} hr</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Dialyser</Td>
              <Td/>
              <Td>{data.dialyser}</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Blood Flow(Qb)</Td>
              <Td/>
              <Td>{data.blood_flow} ml/min</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Dialysate Flow (Qd)</Td>
              <Td/>
              <Td>{data.dialysate_flow} ml/min</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold" rowSpan={4}>Dialysate Concentrate</Td>
              <Td fontWeight="bold">Na</Td>
              <Td>{data.dialysate_na_concentrate} g/L</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">K</Td>
              <Td>{data.dialysate_k_concentrate} mmol/L</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Ca</Td>
              <Td>{data.dialysate_ca_concentrate} mmol/L</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Glucose</Td>
              <Td>{data.dialysate_glucose_concentrate} g/L</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Heparin anticoagulation</Td>
              <Td/>
              <Td>Bolus: {data.heparin_bolus} IU followed by {data.heparin_continuous} IU/hr</Td>
            </Tr>
            <Tr>
              <Td fontWeight="bold">Dry weight goal</Td>
              <Td/>
              <Td>{data.dry_weight_goal} kg</Td>
            </Tr>
          </Tbody>
        </Table>
        ): (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>No dialysis prescribed yet</AlertDescription>
        </Alert>
        )
        }
      </CardBody>
    </Card>
      
      </>

  )}