import React,{useState,useEffect} from 'react';
import { useForm, } from "react-hook-form";
import {
    Box,
    FormLabel,
    FormErrorMessage,
    FormControl,
    HStack,
    Input,
    InputGroup,
    InputRightAddon,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Switch,
    Text,
  } from "@chakra-ui/react"

import useToggle from "services/useToggle"


export default function RenderFields({ fields, handleform}) {
  let {register,errors} = handleform;
  const [isPos,TogglePos] = useToggle();

  const Toggle = (val) =>{
      console.log(val);
  }

  const _ = require("lodash");

  const renderFields = (field) => {
    return fields.map(field => {
            let { name,type,decimals,month,suffix,is_required,validation} = field;
            let snakeName = _.snakeCase(name)

            switch(type){
                case 'text':
                return (
                    <Box>
                        <FormControl isInvalid={errors[snakeName]} isRequired={is_required}>
                            <FormLabel htmlFor={snakeName}>
                                    {name} 
                            </FormLabel>
                            <Input             
                                type='text'
                                name={snakeName}                        
                                {...register(`data.${snakeName} `, { required: true,validate:validation})}
                            />                             
                            {errors[snakeName] && 
                                <FormErrorMessage>
                                    {errors[snakeName].message}
                                </FormErrorMessage> }               
                        </FormControl>
                    </Box>
                );
                case 'number':
                return (
                    <Box>
                        <FormControl isInvalid={errors[snakeName]} isRequired={is_required}>
                            <FormLabel htmlFor={snakeName}>
                                    {name} 
                            </FormLabel>
                            <InputGroup>
                            <NumberInput w='100%' precision={decimals}>
                                <NumberInputField {...register(`data.${snakeName}`)} /> 
                            </NumberInput>   
                            {suffix &&
                                <InputRightAddon children={suffix} /> }
                            {/* <Input type='number' step='1' value="0.00" {...register(name)}/> */}
                             
                            </InputGroup>
                            {errors[snakeName] && 
                                <FormErrorMessage>
                                    {errors[snakeName].message}
                                </FormErrorMessage> }               
                        </FormControl>
                    </Box>
                );
                case 'BP':
                return (
                    <Box>
                        <FormControl isInvalid={errors[snakeName]} isRequired={is_required}>
                            <FormLabel htmlFor={snakeName}>
                                    {name} 
                            </FormLabel>
                            <HStack>
                                <NumberInput w='50%'>
                                    <NumberInputField {...register(`data.${snakeName}-systolic`)} />    
                                </NumberInput>     
                                <p>/</p>
                                <NumberInput w='50%'>
                                    <NumberInputField {...register(`data.${snakeName}-diasystolic`)} />    
                                </NumberInput>  
                            </HStack>     
                            {errors[snakeName] && 
                                <FormErrorMessage>
                                    {errors[snakeName].message}
                                </FormErrorMessage> }               
                        </FormControl>
                    </Box>
                );
                case 'POS/NEG':
                return (
                    <Box>
                        <FormControl isInvalid={errors[snakeName]} isRequired={is_required}>
                            <FormLabel htmlFor={snakeName}>
                                    {name} 
                            </FormLabel>
                            <InputGroup>
                            <Switch size="lg" {...register(`data.${snakeName}`)} onChange={TogglePos}/>
                            {isPos ? 
                                <Text ml={2} fontSize="md" color="red.400">Positive</Text> :
                                <Text ml={2} fontSize="md" color = "green.400">Negative</Text>
                            }
                            </InputGroup>
                            {errors[snakeName] && 
                                <FormErrorMessage>
                                    {errors[snakeName].message}
                                </FormErrorMessage> }               
                        </FormControl>
                    </Box>
                );
            }
        })};
  return renderFields(fields)

}
  