import React, { useState,useEffect,useMemo } from "react";

// Chakra imports
import {
  Avatar,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Box,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spacer,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import {AddIcon, } from '@chakra-ui/icons'
import moment from "moment";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import PatientsTableRow from "components/Tables/PatientsTableRow";
import PatientForm from "components/Patients/PatientForm";
import { tablesProjectData, tablesTableData } from "variables/general";
import PatientsTable from "components/Tables/PatientsTable";

//API Service
import {useFetch} from 'services/useFetch';
import { useTable } from 'react-table'


function Roster() {
  
  const [patients,setPatients]=useState('');
  const[editPatient,setEditPatient]=useState('');
  const textColor = useColorModeValue("gray.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [monday,setMonday] = useState(moment().startOf('isoWeek'));

  const nextWeek = () => {
    setMonday(monday.add(7,"days"));
    console.log(monday);
  }

  const data = React.useMemo(
    () => [
      {
        mon: 'Hello',
        tue: 'World',
        wed:'Wednesday',
        thu:'Thursday',
        fri:'Friday',
        sat:'Saturday',
      },
      {
        mon: 'Hello',
        tue: 'World',
        wed:'Wednesday2',
        thu:'Thursday',
        fri:'Friday',
        sat:'Saturday',
      },
    ],
    []
  )

  const columns = React.useMemo(
    () => [
      {
        // Header: ({ state }) => monday.add(7 * state.pageIndex, "days").format("ddd Do MMM"),
        Header: monday.format("ddd Do MMM"),
        accessor: 'mon', // accessor is the "key" in the data
      },
      {
        Header: 'Tuesday',
        accessor: 'tue',
      },
      {
        Header: 'Wednesday',
        accessor: 'wed',
      },
      {
        Header: 'Thursday',
        accessor: 'thu',
      },
      {
        Header: 'Friday',
        accessor: 'fri',
      },
      {
        Header: 'Saturday',
        accessor: 'sat',
      },
    ],
    []
  )


  // const columns = useMemo(()=>[
  //   {
  //     Header: 'Patient',
  //     accessor: d => `${d.user.first_name} ${d.user.last_name}`,// accessor is the "key" in the data
  //     Cell: row=>{
  //       return(
  //         <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
  //         <Avatar name={row.cell.value} w="50px" borderRadius="12px" me="18px" />
  //         <Flex direction="column">
  //           <Text
  //             fontSize="md"
  //             color={textColor}
  //             fontWeight="bold"
  //             minWidth="100%"
  //           >
  //             {row.value}
  //           </Text>
  //         </Flex>
  //       </Flex>
  //       )

  //     },
  //     id:'name',
  //     show:false,
  //   },
  //   {
  //     Header: 'Medical Aid',
  //     accessor: d => `${d.medical_aid} ${d.medical_aid_number}`, // accessor is the "key" in the data
  //     Cell: row => {
  //       return (
  //         <Flex direction="column">
  //           <Text fontSize="md" color={textColor} fontWeight="bold">
  //             {row.row.original.medical_aid}
  //           </Text>
  //           <Text fontSize="sm" color="gray.400" fontWeight="normal">
  //             {row.row.original.medical_aid_number}
  //           </Text>
  //       </Flex>
  //       )
  //     }
  //   },
  //   {
  //     Header: 'ID',
  //     accessor: 'national_id', // accessor is the "key" in the data
  //     Cell: row =>{
  //       return(
  //         <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
  //           {row.value}
  //       </Text>
  //       )
  //     }
  //   },
  //   {
  //     Header: 'Cellphone',
  //     accessor: 'cellphone',// accessor is the "key" in the data
  //     Cell: row =>{
  //       return(
  //         <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
  //           {row.value}
  //         </Text>
  //       )
  //     } 
  //   },
  //   {
  //     Header: '',
  //     accessor: 'none',// accessor is the "key" in the data
  //     Cell: row => {
  //       return(
  //         <Button p="0px" bg="transparent" variant="no-hover" onClick={() => editClicked(row.row.original)}>
  //           <Text
  //             fontSize="md"
  //             color="gray.400"
  //             fontWeight="bold"
  //             cursor="pointer"
  //           >
  //             Edit
  //           </Text>
  //         </Button>
  //       )
  //     }
  //   },
  // ],[])




  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Patients 
          </Text>
          <Button size="sm" leftIcon={<AddIcon />} colorScheme="teal" onClick={nextWeek}>
            Next Week
          </Button>
          <Spacer/>
        </CardHeader>
        <CardBody>
            <PatientsTable columns={columns} data={data} />
        </CardBody>
      </Card>
    </Flex>
    
  );
}

export default Roster;
