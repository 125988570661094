import React, { useState,useEffect,useMemo } from "react";
import { useTable,usePagination,useFilters,useSortBy,useGlobalFilter,useAsyncDebounce } from 'react-table'
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Button,
    Box,
    Flex,
    IconButton,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Td,
    useColorModeValue,
    Spacer,
    Spinner,
    useDisclosure,
    chakra,
  } from "@chakra-ui/react";
import {
    TriangleDownIcon,
    TriangleUpIcon
  } from "@chakra-ui/icons";
import TableSearch from "./TableSearch";
import DatePicker from "components/DatePicker/DatePicker";
import LabFilter from "components/Labs/LabFilter";
import { AiOutlineSync, } from "react-icons/ai"; 


export default function DateFilterTable({ columns, data }) {

    const [search, setSearch] = React.useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const moment= require('moment') 

    // Update the state when input changes
    const handleSearch = e => {
      const value = e.target.value || undefined;
      setSearch(value);
    };


    const textColor = useColorModeValue("gray.700", "white");
    
    const filterTypes = React.useMemo(() => ({
      dateFilter: (rows, id, filterValue) => {
          return rows = rows.filter(row => {
              return new Date(row.values.date) >= filterValue[0] && new Date(row.values.date) <= filterValue[1];
          });
      },
    }),
        []
    )
    

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page,
        prepareRow,
        setFilter,

        //pagination
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        dispatch,
        state: { pageIndex, pageSize, globalFilter},
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        { columns, 
            data,
            initialState: { pageIndex: 0, pageSize: 10,sortBy: [{id: 'name',desc: false}] },
            filterTypes,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        )

      const onDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        // console.log('start:',start,'end',end)
        // setFilter("date",[start, end]);
      };

      const applyFilter = () => {
        if (startDate && endDate) {
            console.log(startDate,endDate)
            setFilter("date", [startDate, endDate]);
        }
      }

    const autoResetFilter = () => {
      setStartDate(null);
      setEndDate(null);
      setFilter("date",undefined)
    }

      
        
    return (
        <Box  width="100%">
        <Flex mb={2}>
          <Box width={{ lg: "75%" }} mr={2}>
            <DatePicker
              selected={startDate}
              onChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              placeholderText="Select Date Range"
              dateFormat = "dd MMM y"
            />
          </Box>
          <Box>
            <Button mr={2} onClick={applyFilter} colorScheme="teal"> Apply Filter </Button>
            <IconButton 
              variant="outline" 
              colorScheme="teal" 
              aria-label="reset" 
              icon={<AiOutlineSync />}
              onClick={autoResetFilter}
              />
          </Box>
        </Flex>
        <Table size="sm" variant="simple" color={textColor} {...getTableProps()}>
            <Thead>
             {headerGroups.map(headerGroup => (
              <Tr my=".8rem" pl="0px" color="gray.400" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th pl="0px" color="gray.400" {...column.getHeaderProps(column.getSortByToggleProps())} >
                    {column.render('Header')}
                    <chakra.span pl="4">
                        {column.isSorted ? (
                            column.isSortedDesc ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                            )
                        ) : null}
                    </chakra.span>
                  </Th>
                ))}
              </Tr>
            ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </Td>
                      )
                    })}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
          <Box className="pagination" pt={5}>
            <Button size="xs" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
            </Button>{' '}
            <Button size="xs" onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
            </Button>{' '}
            <Button size="xs" onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
            </Button>{' '}
            <Button size="xs" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
            </Button>{' '}
          </Box>
    </Box>
    )
}
