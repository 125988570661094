import React,{useState,useEffect,useCallback} from 'react';

import Select from 'react-select';
//API Service
import {useFetch} from 'services/useFetch';

export default function LabSelect(props){
    const {data,loading,error,fetchData} = useFetch({method:'get',url:"labs/bloodtesttype/"});
    const [bloodTypes,setBloodTypes] = useState([])
    const [options,setOptions] = useState([])
    const _ = require("lodash");

    useEffect( () =>{
        setBloodTypes(data)
        loadOptions(data)
        console.log(bloodTypes)
        console.log(options)
      },[data]);

    const loadOptions = useCallback( (data) =>{
        if (data){
            data.map(field =>{
                console.log(field.name)
                setOptions(oldOptions => [...oldOptions,{label:field.name,value:_.snakeCase(field.name)}])
            })
            console.log(options)
        }
    },[])

    return (
        <Select
            defaultValue={props.default}
            isMulti
            name="Lab Select"
            options={options}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange = {props.onChange}
            placeholder = "Select Line Chart Tests..."
        />
    );
    }