import React,{useState,useEffect} from 'react';

import {SimpleGrid,Flex,Text,useColorModeValue,Spinner} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

//API Service
import {useFetch} from 'services/useFetch';

// NamedNurse components
import Column from "components/NamedNurse/Column.js";

//React-Beautiful-DND
import { DragDropContext } from 'react-beautiful-dnd';


const data = {
    patients:{
        'patient-1': {id: 'patient-1',first_name:'Patient 1'},
        'patient-2': {id: 'patient-2',first_name:'Patient 2'},
        'patient-3': {id: 'patient-3',first_name:'Patient 3'},
        'patient-4': {id: 'patient-4',first_name:'Patient 4'},
    },
    columns:{
        'start-1':{
            id: 'start-1',
            title: 'Hertha',
            patientIds:['reece@vida.capital','reece@figohealth.com','test@figohealth.com'],
        },
        'start-2':{
            id: 'start-2',
            title: 'Uushona',
            patientIds:[],
        },
        'start-3':{
            id: 'start-3',
            title: 'Daniel',
            patientIds:[],
        },
    },
    columnOrder:['start-1','start-2','start-3']
};



function NamedNurse(){

    const [userdata,loading,error] = useFetch({method:'get',url:"accounts/users/"});

    const initialcolumns = {
        'hertha':{
            id: 'hertha',
            title: 'Hertha',
            patientIds:userdata,
        },
        'uushona':{
            id: 'uushona',
            title: 'Uushona',
            patientIds:[],
        },
        'daniel':{
            id: 'daniel',
            title: 'Daniel',
            patientIds:[],
        },
    }

    const textColor = useColorModeValue("gray.700", "white");
    const [columns,setColumns] = useState(data.columns)
    const [patients,setPatients] = useState('')
    const [columnOrder,setColumnOrder] = useState('')

    useEffect( () => {
        setColumns(data.columns);
        setPatients(userdata);
        console.log(patients);
        setColumnOrder(data.columnsOrder);
        console.log(userdata,loading,error);
        
    },[userdata])

    const onDragEnd = result => {
        const {destination, source, draggableId} = result;

        if (!destination){
            return;
        }

        if(
            destination.droppableID === source.droppableId &&
            destination.index === source.index
        ){
            return;
        }

        const start = columns[source.droppableId];
        const finish = columns[destination.droppableId];

        if (start === finish){
            const newPatientIDs = Array.from(start.patientIds);
            newPatientIDs.splice(source.index,1);
            newPatientIDs.splice(destination.index,0,start.patientIds[source.index]);

            const newColumn = {
                ...start,
                patientIds:newPatientIDs,
            }

            setColumns({...columns,[newColumn.id]:newColumn});
            return;
        }

        //moving from one list to another
        const startPatientIDs = Array.from(start.patientIds);
        startPatientIDs.splice(source.index,1);
        const newStart = {
            ...start,
            patientIds:startPatientIDs,
        };

        const finishPatientIDs = Array.from(finish.patientIds);
        finishPatientIDs.splice(destination.index,0,start.patientIds[source.index]);
        const newFinish = {
            ...finish,
            patientIds:finishPatientIDs,
        };
        setColumns({...columns,[newStart.id]:newStart,[newFinish.id]:newFinish})
    }
    

    return(
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Card>
                <CardHeader p="6px 0px 22px 0px">
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Named Nurse
                </Text>
                </CardHeader>
                <CardBody>
                    {!loading ? (
                    <SimpleGrid columns={{sm: 1, md: 3}} spacing={10} width="100%">
                        <DragDropContext onDragEnd={onDragEnd}>
                                    {data.columnOrder.map(columnID => {
                                        const column = columns[columnID]
                                        const colPatients = column.patientIds.map((patientID,index) => patients[index])
                                        console.log(colPatients)
                                        return (
                                            <Column key={column.id} column={column} patients={colPatients}/>
                                        )
                                    })}
                        </DragDropContext>
                    </SimpleGrid>
                    ):(
                        <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                        />
                    )}
                </CardBody>
            </Card>
        </Flex> 
    );
}


export default NamedNurse;