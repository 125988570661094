const getLocalRefreshToken = () => {
    const token = JSON.parse(localStorage.getItem("token"));
    return token?.refresh;
  };
  
  const getLocalAccessToken = () => {
    const token = JSON.parse(localStorage.getItem("token"));
    return token?.access;
  };
  
  const updateLocalAccessToken = (new_token) => {
    let token = JSON.parse(localStorage.getItem("token"));
    token.access = new_token;
    localStorage.setItem("token", JSON.stringify(token));
  };
  
  const getToken = () => {
    return JSON.parse(localStorage.getItem("token"));
  };
  
  const setToken = (token) => {
    console.log(JSON.stringify(token));
    localStorage.setItem("token", JSON.stringify(token));
  };
  
  const removeToken = () => {
    localStorage.removeItem("token");
  };
  
  const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getToken,
    setToken,
    removeToken,
  };
  
  export default TokenService;