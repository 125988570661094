import React,{useState,useEffect} from "react";
import { FaLastfmSquare } from "react-icons/fa";
import { Redirect, Route } from "react-router-dom";
import { Center, Spinner } from "@chakra-ui/react"
import AuthService from "services/auth.service";
import TokenService from "services/token.service";

const ProtectedRoute = ({ component: Component,path,key, ...rest }) => {
  const [auth,setAuth] = useState(false);
  const [isTokenValidated, setIsTokenValidated] = useState(false);


  //validate stored token before allowing access to route
  useEffect(()=>{
    let token = TokenService.getLocalAccessToken();
    console.log(token)
    if (token) {
      AuthService.verifyToken(token)
        .then((resp) => {
          console.log(resp);
          if (resp === 200) {
            setAuth(true);
          }
        })
        .catch((err) => {
          setAuth(false);
          TokenService.removeToken();
          console.log('token removed')
          console.log(error);
        })
        .then(() => setIsTokenValidated(true));
    } else {
      setIsTokenValidated(true); // in case there is no token
   }
  },[]);

  if (!isTokenValidated) return (
    <Center h="100vh">
      <Spinner
        size="xl"
        color="blue.500"
      />
    </Center>);

  return (
    <Route
      path={path}
      key={key}
      render={props =>
          !(auth) ? (
              <Redirect
                  to={{ 
                    pathname: "/auth/login",
                    state: { from: props.location },
                   }}
              />
          ) : (
              <Component {...props} />
              )
      }
      {...rest}
  />
    )
}

export default ProtectedRoute;