import axios from "axios";
import { Redirect } from "react-router-dom";
import TokenService from "./token.service";


const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
  // headers: {
  //   "Content-Type": "application/json",
  // },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'JWT ' + token; 
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    console.log(err)
    console.log(err.response.status)
    if (err.response.status === 401 && originalConfig.url.includes("accounts/jwt/refresh/")){
      console.log('Refresh token expired');
      TokenService.removeToken();
      <Redirect to="/login" />
    } else if (originalConfig.url !== "accounts/jwt/create/" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 || err.response.status === 503 && !originalConfig._retry) {
        originalConfig._retry = true;
        console.log("access token expired")

        try {
          const rs = await instance.post("accounts/jwt/refresh/", {
            refresh: TokenService.getLocalRefreshToken(),
          });
          const accessToken = rs.data.access;
          TokenService.updateLocalAccessToken(accessToken);
          console.log(accessToken);
          console.log("refresh token success")
          return instance(originalConfig);
        } catch (_error) {
          console.log(_error)
          TokenService.removeToken();
          <Redirect to="/auth/login" />
          return _error;
        }
      }

    }

    return Promise.reject(err);
    
  }
);

export default instance;