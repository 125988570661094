import React,{useState,useEffect,useCallback} from "react";
import ReactApexChart from "react-apexcharts";
import { lineChartData, lineChartOptions, rangeOptions } from "variables/charts";

import {useFetch} from 'services/useFetch';
import { Fields } from "./LabForm";
import LabSelect from "./LabSelect";
import { 
  Box,
  Button, 
  ButtonGroup,
  Wrap,
} from "@chakra-ui/react"
import { objectKeys } from "@chakra-ui/utils";

function LabChart(props){
  const {data,loading,error,fetchData} = useFetch({method:'get',url:`labs/bloodtest?patient=${props?.patient.id}`})
  const [chartData,setChartData] = useState([])
  const [chartType,setChartType] = useState('area')
  const [chartOptions,setChartOptions] = useState({})
  const [fields,setFields] = useState([{label:'Haemoglobin',value:'haemoglobin'}])
  const _ = require("lodash");

  useEffect( () =>{
    data && selectChartData(data,fields)
  },[data,fields]);

  const selectChartData = useCallback((labs,labFields) => {
    setChartData([])
    try{
      labFields.map(labField => {
        let newSeries = []
        labs.map(lab =>{
          newSeries.push({x:lab.date,y:lab['data'][labField.value]})
        });
        setChartType('area')
        setChartData(oldArray => [...oldArray,{name:labField.label,data:newSeries}])
        setChartOptions(lineChartOptions)
      });
      console.log(chartData)
    }
    catch (err) {
      console.log(err);
    }
  },[]);

  const handleSelectChange = e =>{
    console.log(e)
    setFields(e);
  }

  const loadBP = () => {
    let startBP = []
    let endBP = []
    setChartOptions({})
    data.map(item => {
      let preSys = _.toNumber(item.data['pre_dialysis_bp-systolic'])
      let preDia = _.toNumber(item.data['pre_dialysis_bp-diasystolic'])
      let postSys = _.toNumber(item.data['post_dialysis_bp-systolic'])
      let postDia = _.toNumber(item.data['post_dialysis_bp-diasystolic'])
      startBP.push({x:item.date,y:[preDia,preSys]})
      endBP.push({x:item.date,y:[postDia,postSys]})
    })
    
    setChartData([{name:'Pre-Dialysis BP',data:startBP},{name:'Post-Dialysis BP',data:endBP}])
    setChartType('rangeBar')
    setChartOptions(rangeOptions)
    
  }




  return(
    <>
      <Wrap>
        <Box w="75%">
          <LabSelect default={fields} onChange={handleSelectChange}/>
        </Box>
        <Box>
          <Button colorScheme="blue" onClick={loadBP}>Blood Pressure</Button>
        </Box>
      </Wrap>
      <Box h="50vh">
        <ReactApexChart
            key = {chartType}
            type = {chartType}
            options={chartOptions}
            series={chartData}
            width="100%"
            height="100%"
          />
      </Box>
    </>
  );
}

export default LabChart;
