// import
import Dashboard from "views/Dashboard/Dashboard.js";
import Tables from "views/Dashboard/Tables.js";
import Billing from "views/Dashboard/Billing.js";
import RTLPage from "views/RTL/RTLPage.js";
import Profile from "views/Dashboard/Profile.js";
import SignIn from "views/Pages/SignIn.js";
import SignUp from "views/Pages/SignUp.js";
import NamedNurse from "views/Dashboard/NamedNurse";
import Patients from "views/Dashboard/Patients";
import Roster from "views/Dashboard/Roster";
import Labs from "views/Dashboard/Labs";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    component: Profile,
    layout: "/admin",
    display:false,
  },
  // {
  //   path: "/profile",
  //   name: "Patients",
  //   rtlName: "لوحة القيادة",
  //   icon: <PersonIcon color="inherit" />,
  //   secondaryNavbar: true,
  //   component: Profile,
  //   layout: "/admin",
  // },
  // {
  //   path: "/namednurse",
  //   name: "Named Nurse",
  //   rtlName: "لوحة القيادة",
  //   icon: <PersonIcon color="inherit" />,
  //   component: NamedNurse,
  //   layout: "/admin",
  // },
  {
    path: "/patients",
    name: "Patient List",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: Patients,
    layout: "/admin",
    display:true,
  },
  {
    path: "/roster",
    name: "Roster",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: Roster,
    layout: "/admin",
    display:false,
  },
  {
    path: "/labs",
    name: "Labs",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: Labs,
    layout: "/admin",
    display:false,
  },
  // {
  //   path: "/billing",
  //   name: "Billing",
  //   rtlName: "لوحة القيادة",
  //   icon: <CreditIcon color="inherit" />,
  //   component: Billing,
  //   layout: "/admin",
  // },
  // {
  //   path: "/rtl-support-page",
  //   name: "RTL",
  //   rtlName: "آرتيإل",
  //   icon: <SupportIcon color="inherit" />,
  //   component: RTLPage,
  //   layout: "/rtl",
  // },
  // {
  //   name: "ACCOUNT PAGES",
  //   category: "account",
  //   rtlName: "صفحات",
  //   state: "pageCollapse",
  //   views: [
      {
        path: "/profile",
        name: "Profile",
        rtlName: "لوحة القيادة",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
        display:false,
      },
      {
        path: "/login",
        name: "Sign In",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color="inherit" />,
        component: SignIn,
        layout: "/auth",
      },
  //     {
  //       path: "/signup",
  //       name: "Sign Up",
  //       rtlName: "لوحة القيادة",
  //       icon: <RocketIcon color="inherit" />,
  //       secondaryNavbar: true,
  //       component: SignUp,
  //       layout: "/auth",
  //     },
  //   ],
  // },
];
export default dashRoutes;
