import React,{ useState, useEffect }  from "react";
import { Redirect,useHistory,useLocation } from "react-router-dom";
// Chakra imports
import {
  Alert,
  AlertIcon, 
  AlertDescription,
  Box,
  CircularProgress,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  SearchIcon,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {ViewIcon} from "@chakra-ui/icons";
// Assets
import signInImage from "assets/img/signInImage.png";
import interior from "assets/img/interior.jpg";

import TokenService from "services/token.service";
import AuthService from "services/auth.service";
import { useAuth } from "context/auth";


function SignIn(props) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isTokenValidated, setIsTokenValidated] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  // const [token,setToken] = useState(TokenService.getLocalAccessToken());
  const { authTokens,setAuthTokens } = useAuth();
  
  let history = useHistory();
  let location = useLocation();
  let { from } = props.location.state || { from: { pathname: "/" } };

  useEffect( () => {
    // if (token) window.location.href = '/dashboard';
    if (authTokens) {
      // AuthService.verifyToken(authTokens.access)
      AuthService.getCurrentUser()
        .then((resp) => {
          console.log(resp);
          if (resp.status === 200) {
            history.replace(from);
          }
        })
        .catch((err) => {
          setAuthTokens();
          TokenService.removeToken();
          console.log('token removed')
          console.log(error);
        })
        .then(() => setIsTokenValidated(true));
    } else {
      setIsTokenValidated(true); // in case there is no token
   }
  },[authTokens]);


  const handleSubmit = async event => {
    event.preventDefault();

    setIsLoading(true);
   
    try {
      console.log({email,password})
      AuthService.login({ email, password }).then(
        (resp)=>{
          // setToken(resp.access);
          setAuthTokens(resp);
          setIsLoading(false);
          setShowPassword(false);
        },
        (error) => {
          console.log(error);
          setError('Invalid username or password');
          setIsLoading(false);
          setEmail('');
          setPassword('');
          setShowPassword(false);
        }
      );
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setIsLoading(false);
      setEmail('');
      setPassword('');
      setShowPassword(false);
    }
  };

  // if (isLoggedIn) {
  //   return <Redirect to={from} />;
  // }
  
  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="48px"
            mt={{ md: "150px", lg: "80px" }}
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Welcome Back
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              Enter your email and password to sign in
            </Text>
            <form onSubmit={handleSubmit}>
              {error && (
                  <Alert status="error" borderRadius={4} mb="20px">
                    <AlertIcon />
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                )}
              <FormControl isRequired>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Email
                </FormLabel>
                <Input
                  borderRadius="15px"
                  mb="24px"
                  fontSize="sm"
                  type="text"
                  placeholder="Your email address"
                  size="lg"
                  onChange={event => setEmail(event.currentTarget.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Password
                </FormLabel>
                <InputGroup size="lg" mb="36px">
                  <Input
                    borderRadius="15px"
                    fontSize="sm"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Your password"
                    onChange={event => setPassword(event.currentTarget.value)}
                  />
                  <InputRightElement width="3rem">
                    <IconButton onClick={handlePasswordVisibility} icon={<ViewIcon />}></IconButton>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
                
                <FormControl display="flex" alignItems="center">
                  {/* <Switch id="remember-login" colorScheme="teal" me="10px" />
                  <FormLabel
                    htmlFor="remember-login"
                    mb="0"
                    ms="1"
                    fontWeight="normal"
                  >
                    Remember me
                  </FormLabel> */}
              
                <Button
                  fontSize="md"
                  type="submit"
                  bg="teal.300"
                  w="100%"
                  h="45"
                  mb="20px"
                  color="white"
                  mt="20px"
                  isLoading={isLoading}
                  loadingText="Logging In"
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}
                >
                Sign In
                </Button>
                
              </FormControl>
            </form>  
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              {/* <Text color={textColor} fontWeight="medium">
                Don't have an account?
                <Link color={titleColor} as="span" ms="5px" fontWeight="bold">
                  Sign Up
                </Link>
              </Text> */}
            </Flex>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={interior}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
