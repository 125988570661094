import React,{useState,useEffect,useMemo,useRef} from 'react';
import { useForm,Controller,FormProvider } from "react-hook-form";
import axios from "axios";
import {
    Box,
    Button,
    FormLabel,
    FormErrorMessage,
    FormControl,
    Input,
    InputGroup,
    InputLeftElement,
    InputLeftAddon,
    InputRightAddon,
    // Select,
    Stack,
    SimpleGrid,
    Textarea,
  } from "@chakra-ui/react"
import { useToast } from "@chakra-ui/react"
import RenderFields from './RenderFields';
import { SearchIcon } from '@chakra-ui/icons'


//API Service
import {useFetch} from 'services/useFetch';
import API from 'services/api'
import TokenService from "services/token.service";
import AuthService from "services/auth.service";

//Custome DatePicker
import { SingleDatepicker } from 'chakra-dayzed-datepicker'
// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// import 'components/DatePicker/date-picker.css';
import DatePicker from 'components/DatePicker/DatePicker';
import moment from 'moment';
import FileUpload from 'components/Forms/FileUpload';

//select-search
import Select from 'react-select';

import {useLocation} from "react-router-dom";
import authService from 'services/auth.service';

export const Fields = [
    {
        name:'firstName' ,
        type:'text',
        title:'First Name',
        required:{
            value:true,
            message:'Employee First Name is required'
        },
        validation:function(val){
            return val.length >=5 || 'Min Length is 5';
        }
    },
    {
        name:'bloodPressuse' ,
        type:'BP',
        title:'BloodPressure',
        required:{
            value:false,
            message:'Employee Last Name is required'
        },
    },
    {
        name:'platelets' ,
        type:'number',
        title:'Platelets',
        decimals:2,
        suffix:'%',
        required:{
            value:false,
            message:'Employee Last Name is required'
        },
    },

]

function LabForm(props) {
    const toast = useToast()
    const {data,loading,error,fetchData} = useFetch({method:'get',url:"labs/bloodtesttype/"});
    const {data:pData,loading:pLoading,error:pError,fetchData:pFetchData} = useFetch({method:'get',url:"accounts/patients/"})
    const [fields,setFields] = useState('');
    const [options,setOptions] = useState('');
    const[patients,setPatients] = useState('');
    const [date, setDate] = useState(new Date());
    const inputRef = useRef();
    const token = TokenService.getLocalAccessToken();
    let patientProp = useLocation();

    useEffect(() => {
        console.log(data);
        setFields(data);
        console.log(pData);
        console.log('patientProps:',patientProp?.state)
        setPatients(createPatientOptions(pData));
    },[data,pData])   

    const createPatientOptions = (p) => {
        let patientOptions = []
        if (p){
        p.map((patient) => {
            patientOptions.push({value:patient.id,label:`${patient.user.first_name} ${patient.user.last_name}`})
        })}
        console.log(patientOptions)
        return patientOptions
    }

    const {
        control,
        handleSubmit,
        register,
        setValue,
        reset,
        formState: { errors, isSubmitting },
        setError,
        watch,
      } = useForm()

    async function onSubmit(formData){

        //force token refresh
        AuthService.refreshToken();

        //create form data
        console.log(formData);
        const uploads = new FormData();
        uploads.append("pdf", formData.pdf[0]);
        uploads.append("date",formData.date)
        uploads.append("patient",formData.patient)
        uploads.append("data",JSON.stringify(formData.data))
        console.log('Uploads: ',uploads)

        // API.defaults.headers.post['Content-Type'] = 'multipart/form-data'
        // API.defaults.headers.common['Accept'] = 'multipart/form-data'
        console.log(API.defaults)
        // await axios.post("http://127.0.0.1:8000/api/v1/labs/bloodtest/",formData,{headers:{'content-type': 'multipart/form-data'}})    

        //Post data
        await API({
            method: 'POST',
            url:`labs/bloodtest/`,
            data: uploads,
            timeout: 30000,
            // headers: {
            //     'Content-Type': 'multipart/form-data',
            // }
          })
          .then(resp => {
            console.log(resp)
            toast({
                title: "New Blood Test Created.",
                description: `Blood Test Created`,
                status: "success",
                duration: 9000,
                isClosable: true,
            })
          })
          .catch(err => {
            console.log(err)
            
            try {
              for (let [field, messages] of Object.entries(err.response.data)) {
                // console.log(`key: ${field}, value: ${err.response.data[field]}`)
                setError(field, { type: 'server', message: messages.join(' ') });
                
              }
              console.log(err.response.data)
            }
            catch (error){
              console.log(error);
            }
          })
    }

    let handleform={
        register:register,
        errors:errors,
     };

    return (
        <>
        {loading && <p>LOADING form</p>}
        {pLoading && <p>LOADING patients</p>}

        <form onSubmit={handleSubmit(onSubmit)}>
            <SimpleGrid columns={2} spacing="24px">
                <FormControl isInvalid={errors?.date} isRequired>
                    <FormLabel htmlFor="date">Collection Date</FormLabel>
                    <Controller
                        control={control}
                        name="date"
                        rules={{ required: 'This field is required' }}
                        render={({ field: {onChange, onBlur, value, ref } }) => (
                            <DatePicker 
                              dateFormat="yyyy-MM-dd"
                              onChange={(e) => onChange(moment(e).format('YYYY-MM-DD'))}
                              onBlur={onBlur}
                              selected={moment(value).toDate()}
                            />
                        )}
                    />
                    <FormErrorMessage>
                          {errors?.date && errors?.date.message}
                    </FormErrorMessage>
                </FormControl>
                {/* <FormControl isInvalid={errors?.blood_type}>
                    <FormLabel htmlFor="date">Collection Date</FormLabel>
                    <SingleDatepicker
                        date={date}
                        onDateChange={setDate}
                        {...register('date')}
                    />
                </FormControl> */}
                <FormControl isInvalid={errors?.patient} isRequired>
                    <FormLabel htmlFor="patient">Patient</FormLabel>
                    {patients ?
                    // <Select placeholder="Select patient" 
                    //     defaultValue = {{ label:`Alistair`,value:85 }}
                    //     {...register('patient',{required: "Required",})}>
                    //     {patients.map((patient) => 
                    //         <option value={patient.id}>{patient.user.first_name} {patient.user.last_name}</option>
                    //     )}
                    // </Select>
                        <Controller
                        control={control}
                        name="patient"
                        defaultValue={patientProp?.state?.patient.id}
                        rules={{ required: 'This field is required' }}
                        render={({ field: {onChange, onBlur, value, ref } }) => (
                            <Select 
                                inputRef={ref}
                                options={patients} 
                                value={patients.find(c => c.value === value)}
                                onChange={val => onChange(val.value)}
                            />
                        )}/>
                    :''}
                    <FormErrorMessage>
                          {errors?.patient && errors?.patient.message}
                    </FormErrorMessage>
                </FormControl>
               
               {fields &&
                <RenderFields
                    fields={fields}
                    handleform={handleform}
                />}

                {/* <FormControl isInvalid={errors?.pdf}>
                    <FormLabel htmlFor="pdf">Upload PDF</FormLabel>
                    <Controller
                        control={control}
                        name="pdf"
                        render={({ field: {onChange } }) => (
                            <Dropzone
                              onChange={(e) => onChange(e.target.files[0])}
                            />
                        )}
                    />
                    <FormErrorMessage>
                          {errors?.pdf && errors?.pdf.message}
                    </FormErrorMessage>
                </FormControl> */}

                {/* <FormControl isRequired>
                    <FormLabel htmlFor="writeUpFile">Upload PDF</FormLabel>
                    <InputGroup>
                        <InputLeftElement
                        pointerEvents="none"
                        children={<SearchIcon />}
                        />
                        <input {...register("pdf")} 
                            type='file' 
                            name='pdf' 
                            ref={inputRef} 
                            style={{ display: 'none' }}
                            onChange={(e) => setFilePlaceholder(e.target.files[0])}>
                        </input>
                        <Input
                        placeholder={filePlaceholder || "Your file ..."}
                        onClick={() => inputRef.current.click()}
                        cursor= 'pointer'
                        />
                    </InputGroup>
                    <FormErrorMessage>
                        {errors?.pdf && errors?.pdf.message}
                    </FormErrorMessage>
                </FormControl> */}
                {/* <FileUpload
                    name ='pdf'
                    acceptedFileTypes ='.pdf'
                    control={control}
                    children='Upload'
                />     */}
                <FormControl isInvalid={errors?.pdf}>
                    <FormLabel htmlFor="pdf">Upload PDF</FormLabel>
                    <input id="pdf" {...register("pdf")} type="file" />
                    <FormErrorMessage>
                        {errors?.pdf && errors?.pdf.message}
                    </FormErrorMessage>
                </FormControl> 

            </SimpleGrid>
            
            <Button mt={2} colorScheme="blue" isLoading={isSubmitting} type="submit">Submit</Button>
        </form>
    
        </>
    );
}

export default LabForm;