import React, { useState,useEffect,useMemo } from "react";
import { useTable,usePagination,useSortBy,useGlobalFilter,useAsyncDebounce } from 'react-table'
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Button,
    Box,
    Flex,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Td,
    useColorModeValue,
    Spacer,
    Spinner,
    useDisclosure,
    chakra,
  } from "@chakra-ui/react";
import {
    TriangleDownIcon,
    TriangleUpIcon
  } from "@chakra-ui/icons";
import TableSearch from "./TableSearch";


export default function PatientsTable({ columns, data }) {

    const [search, setSearch] = React.useState('');

    // Update the state when input changes
    const handleSearch = e => {
      const value = e.target.value || undefined;
      setSearch(value);
    };

    const textColor = useColorModeValue("gray.700", "white");
 
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page,
        prepareRow,
        setFilter,

        //pagination
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter},
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        { columns, 
            data,
            initialState: { pageIndex: 0, pageSize: 10,sortBy: [{id: 'name',desc: false}] },
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        )
        
    return (
        <Box  width="100%">
        <TableSearch
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <Table size="sm" variant="simple" color={textColor} {...getTableProps()}>
            <Thead>
             {headerGroups.map(headerGroup => (
              <Tr my=".8rem" pl="0px" color="gray.400" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th pl="0px" color="gray.400" {...column.getHeaderProps(column.getSortByToggleProps())} >
                    {column.render('Header')}
                    <chakra.span pl="4">
                        {column.isSorted ? (
                            column.isSortedDesc ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                            )
                        ) : null}
                    </chakra.span>
                  </Th>
                ))}
              </Tr>
            ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </Td>
                      )
                    })}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
          <Box className="pagination" pt={5}>
            <Button size="xs" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
            </Button>{' '}
            <Button size="xs" onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
            </Button>{' '}
            <Button size="xs" onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
            </Button>{' '}
            <Button size="xs" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
            </Button>{' '}
          </Box>
    </Box>
    )
}
